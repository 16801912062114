<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Validasi</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item active">Validasi Uji Tera / Tera Ulang / Kalibrasi /
                                    Pengujian
                                </li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih">
                                <div class="card-header border-transparent">
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                    <div class="col-sm-12 float-left pb-2 px-3 wow fadeInDown">
                                        Jumlah Data {{ G_numFormat(jumlahdata) }}
                                        <span style="float: right;">
                                            <span>Halaman ke: </span>
                                            <select v-model="halaman" @change="onChangeHalaman($event)"
                                                v-if="jumlahhalaman > 0">
                                                <option v-for="index in jumlahhalaman" :key="index">
                                                    {{ index }}</option>
                                            </select>
                                            <select v-model="jumlahhalaman" @change="onChangeHalaman($event)" v-else>
                                                <option>0</option>
                                            </select>
                                            <span> dari </span> {{ jumlahhalaman }}
                                        </span>
                                    </div>
                                </div>
                                <div class="card-body p-0">
                                    <div class="table-responsive table-wrapper">
                                        <table class="table m-0" id="tabeldata" style="font-size: smaller;">
                                            <thead>
                                                <tr>
                                                    <th style="width: 50px;" rowspan="2">No</th>
                                                    <th style="width: 150px;" rowspan="2">Tanggal</th>
                                                    <th style="width: 250px;" rowspan="2">Pemohon</th>
                                                    <th style="width: 100px;" rowspan="2">Lampiran</th>
                                                    <th style="width: 100px;" colspan="2">Opsi</th>
                                                </tr>
                                                <tr>
                                                    <th style="width: 50px;">Tidak<br>Lolos</th>
                                                    <th style="width: 50px;">Lolos</th>
                                                </tr>
                                            </thead>
                                            <template v-for="(datalist, urutlist) in filteredKataData" :key="urutlist">
                                                <tbody>
                                                    <tr v-if="datalist.tahapan <= 2">
                                                        <td>{{ urutlist + 1 }}</td>
                                                        <td>
                                                            {{ G_formatDate(datalist.tanggal) }}<br>
                                                            Tahapan:<br>
                                                            {{ datalist.namatahapan }}
                                                        </td>
                                                        <td>
                                                            {{ datalist.pemohon }}<br>{{ datalist.alamatpemohon }}
                                                        </td>
                                                        <td class="text-center">
                                                            <a :href="folder_foto + datalist.filenya_surat"><i
                                                                    class="far fa-file-pdf"></i>
                                                                Lihat<br>Surat<br>Permohonan</a>
                                                        </td>
                                                        <td class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    :name="'radio' + datalist.id"
                                                                    @click="tidaklolos(datalist.id, datalist.detail.length)"
                                                                    :checked="datalist.tahapan == 1">
                                                                <label class="form-check-label">Semua Tidak
                                                                    Lolos</label>
                                                            </div>

                                                        </td>
                                                        <td class="text-center">
                                                            <div class="form-check">
                                                                <input class="form-check-input" type="radio"
                                                                    :name="'radio' + datalist.id"
                                                                    @click="lolos(datalist.id, datalist.detail.length)"
                                                                    :checked="datalist.tahapan == 2">
                                                                <label class="form-check-label">Semua Lolos</label>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6" class="levelsubsubkegiatan">Detail Alat</td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="3" class="levelsubsubkegiatan">Identitas Alat</td>
                                                        <td class="levelsubsubkegiatan text-center">Jumlah Alat</td>
                                                        <td colspan="2" class="levelsubsubkegiatan text-center">Opsi
                                                        </td>
                                                    </tr>
                                                    <template v-for="(datadetail, urutdetail) in datalist.detail"
                                                        :key="urutdetail">
                                                        <tr>
                                                            <td class="levelpaket">
                                                                {{ urutlist + 1 }}.{{ urutdetail + 1 }}
                                                            </td>
                                                            <td class="levelpaket" colspan="2">
                                                                Nama Alat: {{ datadetail.namalat }}, No Seri: {{
                                                                    datadetail.noseri }}<br>
                                                                Merk: {{ datadetail.merk }},
                                                                Tipe: {{ datadetail.tipe }}<br>
                                                                Kapasitas: {{ datadetail.kapasitas }},
                                                                Daya Baca: {{ datadetail.dayabaca }}<br>
                                                                Buatan: {{ datadetail.buatan }}
                                                            </td>
                                                            <td class="levelpaket text-center">
                                                                {{ datadetail.jumlah }}
                                                            </td>
                                                            <td class="levelpaket text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio"
                                                                        :name="'radio' + datalist.id + (urutdetail + 1)"
                                                                        :id="'radiotidaklolos' + datalist.id + (urutdetail + 1)"
                                                                        :checked="datadetail.status_lolos == 1">
                                                                    <label class="form-check-label">Tidak Lolos</label>
                                                                </div>
                                                            </td>
                                                            <td class="levelpaket text-center">
                                                                <div class="form-check">
                                                                    <input class="form-check-input" type="radio"
                                                                        :name="'radio' + datalist.id + (urutdetail + 1)"
                                                                        :id="'radiololos' + datalist.id + (urutdetail + 1)"
                                                                        :checked="datadetail.status_lolos == 2">
                                                                    <label class="form-check-label">Lolos</label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                    <tr>
                                                        <td colspan="6" class="levelkegiatan">Penugasan Order</td>
                                                    </tr>
                                                    <tr>
                                                        <td class="levelkegiatan" colspan="2">
                                                            <label>Bagian <small class="text-info"> (*wajib
                                                                    dipilih)</small></label>
                                                            <v-select :options="bagian" :reduce="(label) => label.code"
                                                                label="label" v-model="defaultSelectedbagian"
                                                                @update:modelValue="onbagianChange($event, datalist.id)"
                                                                style="background-color: #ffffff;"
                                                                :id="'bagian' + datalist.id"></v-select>
                                                        </td>
                                                        <td class="levelkegiatan">
                                                            <label>Penugasan <small class="text-info"> (*wajib
                                                                    dipilih)</small></label>
                                                            <v-select :options="tugas" :reduce="(label) => label.code"
                                                                label="label" v-model="defaultSelectedtugas"
                                                                @update:modelValue="ontugasChange($event, datalist.id)"
                                                                style="background-color: #ffffff;"
                                                                :id="'tugas' + datalist.id"></v-select>
                                                        </td>
                                                        <td class="levelkegiatan" colspan="2">
                                                            <label>Nomor Order <small class="text-info"> (*wajib
                                                                    diisi)</small></label>
                                                            <div class="input-group mb-3">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i
                                                                            class="fab fa-creative-commons-share"></i></span>
                                                                </div>
                                                                <input type="text" class="form-control form-control-sm"
                                                                    :id="'noorder' + datalist.id" name="noorder"
                                                                    placeholder="Nomor Order" v-model="noorder">
                                                            </div>
                                                        </td>
                                                        <td class="levelkegiatan">
                                                            <label>Tanggal Order <small class="text-info"> (*wajib
                                                                    diisi)</small></label>
                                                            <div class="input-group mb-3">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i
                                                                            class="fab fa-creative-commons-share"></i></span>
                                                                </div>
                                                                <input type="date" class="form-control form-control-sm"
                                                                    :id="'tanggalorder' + datalist.id"
                                                                    name="tanggalorder" placeholder="Tanggal Order"
                                                                    :value="datalist.tanggalorder">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="levelkegiatan" colspan="3">
                                                            <label>Petugas <small class="text-info"> (*wajib
                                                                    dipilih)</small></label>
                                                            <v-select :options="signby" :reduce="(label) => label.code"
                                                                label="label" v-model="defaultSelectedsignby"
                                                                @update:modelValue="onsignbyChange($event, datalist.id)"
                                                                style="background-color: #ffffff;"
                                                                :id="'signby' + datalist.id"></v-select>
                                                        </td>
                                                        <td class="levelkegiatan" colspan="2">
                                                            <label>Jenis Tarif <small class="text-info"> (*wajib
                                                                    dipilih)</small></label>
                                                            <v-select :options="tarif" :reduce="(label) => label.code"
                                                                label="label" v-model="defaultSelectedtarif"
                                                                @update:modelValue="ontarifChange($event, datalist.id)"
                                                                style="background-color: #ffffff;"
                                                                :id="'tarif' + datalist.id"></v-select>
                                                        </td>
                                                        <td class="levelkegiatan">
                                                            <label>Tanggal Selesai <small class="text-info"> (*wajib
                                                                    diisi)</small></label>
                                                            <div class="input-group mb-3">
                                                                <div class="input-group-prepend">
                                                                    <span class="input-group-text"><i
                                                                            class="fab fa-creative-commons-share"></i></span>
                                                                </div>
                                                                <input type="date" class="form-control form-control-sm"
                                                                    :id="'tanggalselesai' + datalist.id"
                                                                    name="tanggalselesai" placeholder="Tanggal Selesai"
                                                                    :value="datalist.tanggalselesai">
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colspan="6" class="levelkegiatan text-center">
                                                            <button type="button"
                                                                class="btn btn-success3 btn-sm text-white lebar2"
                                                                @click="prosesdata(datalist.id, datalist.detail.length)"
                                                                data-toggle="tooltip" data-placement="left"
                                                                title="Proses Order..">
                                                                <i class="fas fa-plus"></i> Proses Order
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </template>
                                            <tbody v-if="(datapesan == 'data kosong')">
                                                <tr>
                                                    <td colspan="6">Data masih kosong</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div class="card-footer p-3">

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 text-center">&nbsp;</div>
        <div class="col-sm-12 text-center">&nbsp;</div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    components: {
        Footer, NavBar, Menu, vSelect, swal
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            datapesan: '',
            datamaster: [],
            carikata: '',
            folder_foto: process.env.VUE_APP_URL_API + 'parastapainnovation_pengujian-Lampiran?f=',
            jumlahdata: 0,
            jumlahhalaman: 0,
            mulai: 0,
            halaman: 1,
            counter: 0,
            koodinatsetujus: [
                {
                    previous: '',
                    expiration: '',
                }
            ],
            bagian: [],
            databagian: [],
            defaultSelectedbagian: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            tugas: [],
            datatugas: [],
            defaultSelectedtugas: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },

            signby: [],
            datasignby: [],
            defaultSelectedsignby: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedsignby: 0,

            tarif: [],
            datatarif: [],
            defaultSelectedtarif: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedtarif: 0,
        }
    },
    computed: {
        filteredKataData() {
            const filteredWorkers = this.carikata === ""
                ? this.datamaster
                : this.datamaster.filter(wo => Object.values(wo).join("").toLowerCase().indexOf(this.carikata.toLowerCase()) !== -1);
            return filteredWorkers;
        },
        JumlahfilteredKataData() {
            var jumlah = 0;
            try {
                if (this.filteredKataData === undefined) {
                    jumlah = 0;
                } else {
                    jumlah = this.filteredKataData.length;
                }
            }
            catch {
                jumlah = 0;
            }
            return jumlah;
        },
    },
    methods: {
        prosesdata(id, jumlah) {
            var statusnya = 'false';
            for (let i = 1; i <= jumlah; i++) {
                if ((document.getElementById('radiololos' + id + i).checked == false) && (document.getElementById('radiotidaklolos' + id + i).checked == false)) {
                    statusnya = 'false';
                }
                else if ((document.getElementById('radiololos' + id + i).checked == true) || (document.getElementById('radiotidaklolos' + id + i).checked == true)) {
                    statusnya = 'true';
                }
                else {
                    statusnya = 'true';
                }
            }
            if (statusnya == 'false') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Pilih Status Lolos Alat',
                });
                return false;
            }
            else {
                var lolosnya = 'tidak lolos';
                for (let i = 1; i <= jumlah; i++) {
                    if (document.getElementById('radiotidaklolos' + id + i).checked == true) {
                        lolosnya = 'tidak lolos';
                    }
                    else if (document.getElementById('radiololos' + id + i).checked == true) {
                        lolosnya = 'ada lolos';
                    }
                    else {
                        lolosnya = 'ada lolos';
                    }
                }
                if (lolosnya == 'tidak lolos') {
                    swal.fire({
                        title: "Konfirmasi",
                        text: "Yakin Hendak Update Data Order?",
                        icon: "warning",
                        showCancelButton: true,
                        cancelButtonText: "Batal",
                        confirmButtonColor: "#DC143C",
                        cancelButtonColor: "#ffaa05",
                        confirmButtonText: "Update"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            var status_lolos = '';
                            for (let i = 1; i <= jumlah; i++) {
                                if (i == 1) {
                                    status_lolos = status_lolos + '1';
                                }
                                else {
                                    status_lolos = status_lolos + '|' + '1';
                                }
                            }

                            var fd = new FormData();
                            fd.append("id", id);
                            fd.append("tahapan", '1');
                            fd.append("status_lolos", status_lolos);
                            fd.append("validdby", JSON.parse(localStorage.getItem("usistem")));
                            const mainAPI = axios.create({
                                baseURL: process.env.VUE_APP_URL_API,
                                headers: {
                                    "Content-Type": "form-data",
                                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                                },
                            });
                            mainAPI.post("parastapainnovation_pengujian-UpdateTidakLolos", fd).then(
                                Response => {
                                    if (Response.data.response == 'error') {
                                        swal.fire('Peringatan', Response.data.message, 'error');
                                    }
                                    else {
                                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                                            window.location.replace("/Proses");
                                        });
                                    }
                                }
                            ).catch(function (error) {
                                swal.mixin({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000
                                }).fire({
                                    icon: 'warning',
                                    title: error
                                });
                            }
                            )
                        }
                    });
                    return false;
                }
                //alert(lolosnya);
                //return false;
                if (lolosnya == 'ada lolos') {
                    if ((this.selectedbagian == '') || (this.selectedbagian == undefined) || (this.selectedbagian == null)) {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: 'Mohon Pilih Data',
                        });
                        return false;
                    }
                    else {
                        var cek = this.selectedbagian.split(",");
                        if (cek[0] != id) {
                            swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000
                            }).fire({
                                icon: 'warning',
                                title: 'Mohon Pilih Data',
                            });
                            return false;
                        }
                        else {
                            var bagian = cek[1];
                        }
                    }

                    if ((this.selectedtugas == '') || (this.selectedtugas == undefined) || (this.selectedtugas == null)) {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: 'Mohon Pilih Data',
                        });
                        return false;
                    }
                    else {
                        var cek = this.selectedtugas.split(",");
                        if (cek[0] != id) {
                            swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000
                            }).fire({
                                icon: 'warning',
                                title: 'Mohon Pilih Data',
                            });
                            return false;
                        }
                        else {
                            var tugas = cek[1];
                        }
                    }
                    if (document.getElementById('noorder' + id).value == '') {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: 'Mohon Isi Data',
                        });
                        return false;
                    }
                    else {
                        var noorder = document.getElementById('noorder' + id).value;
                    }

                    if (document.getElementById('tanggalorder' + id).value == '') {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: 'Mohon Isi Data',
                        });
                        return false;
                    }
                    else {
                        var tanggalorder = document.getElementById('tanggalorder' + id).value;
                    }

                    if ((this.selectedsignby == '') || (this.selectedsignby == undefined) || (this.selectedsignby == null)) {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: 'Mohon Pilih Data',
                        });
                        return false;
                    }
                    else {
                        var cek = this.selectedsignby.split(",");
                        if (cek[0] != id) {
                            swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000
                            }).fire({
                                icon: 'warning',
                                title: 'Mohon Pilih Data',
                            });
                            return false;
                        }
                        else {
                            var signby = cek[1];
                        }
                    }

                    if ((this.selectedtarif == '') || (this.selectedtarif == undefined) || (this.selectedtarif == null)) {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: 'Mohon Pilih Data',
                        });
                        return false;
                    }
                    else {
                        var cek = this.selectedtarif.split(",");
                        if (cek[0] != id) {
                            swal.mixin({
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000
                            }).fire({
                                icon: 'warning',
                                title: 'Mohon Pilih Data',
                            });
                            return false;
                        }
                        else {
                            var tarif = cek[1];
                        }
                    }

                    if (document.getElementById('tanggalselesai' + id).value == '') {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: 'Mohon Isi Data',
                        });
                        return false;
                    }
                    else {
                        var tanggalselesai = document.getElementById('tanggalselesai' + id).value;
                    }

                    swal.fire({
                        title: "Konfirmasi",
                        text: "Yakin Hendak Update Data Order?",
                        icon: "warning",
                        showCancelButton: true,
                        cancelButtonText: "Batal",
                        confirmButtonColor: "#DC143C",
                        cancelButtonColor: "#ffaa05",
                        confirmButtonText: "Update"
                    }).then((result) => {
                        if (result.isConfirmed) {
                            var status_lolos = '';
                            var isi_lolos = '';
                            for (let i = 1; i <= jumlah; i++) {
                                if (document.getElementById('radiotidaklolos' + id + i).checked == true) {
                                    isi_lolos = '1';
                                }
                                else if (document.getElementById('radiololos' + id + i).checked == true) {
                                    isi_lolos = '2';
                                }
                                else {
                                    isi_lolos = '2';
                                }
                                if (i == 1) {
                                    status_lolos = status_lolos + isi_lolos;
                                }
                                else {
                                    status_lolos = status_lolos + '|' + isi_lolos;
                                }
                            }

                            var fd = new FormData();
                            fd.append("id", id);
                            fd.append("tahapan", '4');
                            fd.append("status_lolos", status_lolos);
                            fd.append("noorder", noorder);
                            fd.append("tanggalorder", tanggalorder);
                            fd.append("tanggalselesai", tanggalselesai);
                            fd.append("bagian", bagian);
                            fd.append("signby", signby);
                            fd.append("tarif", tarif);
                            fd.append("tugas", tugas);
                            fd.append("validdby", JSON.parse(localStorage.getItem("usistem")));
                            const mainAPI = axios.create({
                                baseURL: process.env.VUE_APP_URL_API,
                                headers: {
                                    "Content-Type": "form-data",
                                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                                },
                            });
                            mainAPI.post("parastapainnovation_pengujian-UbahOrder", fd).then(
                                Response => {
                                    if (Response.data.response == 'error') {
                                        swal.fire('Peringatan', Response.data.message, 'error');
                                    }
                                    else {
                                        swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                                            window.location.replace("/Proses");
                                        });
                                    }
                                }
                            ).catch(function (error) {
                                swal.mixin({
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000
                                }).fire({
                                    icon: 'warning',
                                    title: error
                                });
                            }
                            )
                        }
                    });
                    return false;
                }
            }
        },
        lolos(id, jumlah) {
            for (let i = 1; i <= jumlah; i++) {
                document.getElementById('radiololos' + id + i).checked = true;
            }
        },
        tidaklolos(id, jumlah) {
            for (let i = 1; i <= jumlah; i++) {
                document.getElementById('radiotidaklolos' + id + i).checked = true;
            }
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
        },
        async ambilreff() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_pengujian-GetBagian?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.databagian = [];
                        this.bagian = [];
                        this.databagian = Response.data.content.data;
                        this.databagian.forEach((item) => {
                            this.bagian.push({
                                label: item.nama,
                                code: this.databagian.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.bagian.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                        this.halamanloading = false;
                    }
                    else {
                        swal.fire('Peringatan', error, 'error');
                        this.halamanloading = false;
                        return false;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            });
            await mainAPI.get("parastapainnovation_pengujian-GetPenugasan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatugas = [];
                        this.tugas = [];
                        this.datatugas = Response.data.content.data;
                        this.datatugas.forEach((item) => {
                            this.tugas.push({
                                label: item.nama,
                                code: this.datatugas.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.tugas.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                        this.halamanloading = false;
                    }
                    else {
                        swal.fire('Peringatan', error, 'error');
                        this.halamanloading = false;
                        return false;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            });
            await mainAPI.get("parastapainnovationuser-GetPetugas?random=" + random + "&level=2").then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datasignby = [];
                        this.signby = [];
                        this.datasignby = Response.data.content.data;
                        this.datasignby.forEach((item) => {
                            this.signby.push({
                                label: item.nama,
                                code: this.datasignby.indexOf(item) + 1,
                                tag: item.tmpsys,
                            });
                        });
                        this.signby.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                        this.halamanloading = false;
                    }
                    else {
                        swal.fire('Peringatan', error, 'error');
                        this.halamanloading = false;
                        return false;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            });
            await mainAPI.get("parastapainnovation_order-JenisTarif?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datatarif = [];
                        this.tarif = [];
                        this.datatarif = Response.data.content.data;
                        this.datatarif.forEach((item) => {
                            this.tarif.push({
                                label: item.nama,
                                code: this.datatarif.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.tarif.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                        this.halamanloading = false;
                    }
                    else {
                        swal.fire('Peringatan', error, 'error');
                        this.halamanloading = false;
                        return false;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            });
        },
        onbagianChange(a, id) {
            try {
                this.selectedbagian = id + ',' + this.bagian[a].tag;
            }
            catch (err) {
                return;
            }
        },
        ontugasChange(a, id) {
            try {
                this.selectedtugas = id + ',' + this.tugas[a].tag;
            }
            catch (err) {
                return;
            }
        },
        onsignbyChange(a, id) {
            try {
                this.selectedsignby = id + ',' + this.signby[a].tag;
            }
            catch (err) {
                return;
            }
        },
        ontarifChange(a, id) {
            try {
                this.selectedtarif = id + ',' + this.tarif[a].tag;
            }
            catch (err) {
                return;
            }
        },
        async ambildata() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_pengujian-GetProsesPengujian?random=" + random + "&level=" + this.leveluser + "&user=" + JSON.parse(localStorage.getItem("usistem"))).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datamaster = [];
                        this.datamaster = Response.data.content.data;
                        this.jumlahdata = Response.data.content.jumlahdata;
                        this.jumlahhalaman = Response.data.content.jumlahhalaman;
                        this.mulai = Response.data.content.mulai;
                        if (this.mulai == 0) {
                            this.halaman = 1;
                        }
                        else {
                            this.halaman = (this.mulai / 20) + 1;
                        }
                    }
                    else {
                        this.datamaster = [];
                        this.jumlahdata = 0;
                        this.jumlahhalaman = 0;
                        this.mulai = 0;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                console.log(error);
                this.ClearlocalStorage();
                this.halamanloading = false;
            });
        },
        hapus(kode) {
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Hendak Hapus Data?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#DC143C",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Hapus"
            }).then((result) => {
                if (result.isConfirmed) {
                    const data = { kode: kode, deletedby: JSON.parse(localStorage.getItem("usistem")) }
                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                    });
                    mainAPI.post("parastapainnovation_pengujian-Delete", data).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah dihapus..', 'success').then(function () {
                                    window.location.replace("/Pengujian");
                                });
                            }
                        }
                    ).catch(
                        error => {
                            swal.fire('Peringatan', 'Terjadi Kesalahan Format Data', 'error');
                        }
                    )

                }
            });
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.ambilreff();
        this.ambildata();
        //alert(JSON.parse(localStorage.getItem("tokensistem")));
    }
}
</script>
<style></style>