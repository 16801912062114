<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Pengaturan</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/Pengujian">Data Uji Tera / Tera Ulang / Kalibrasi
                                        / Pengujian</a></li>
                                <li class="breadcrumb-item active">Update Data</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih2">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Permohonan</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-6">
                                            <label>Nama Pemilik<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="pemilik"
                                                    name="pemilik" placeholder="Nama Pemilik" v-model="pemilik" readonly>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Alamat Pemilik<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm"
                                                    id="alamatpemilik" name="alamatpemilik" placeholder="Alamat Pemilik"
                                                    v-model="alamatpemilik" readonly>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Nama Pemohon<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm" id="pemohon"
                                                    name="pemohon" placeholder="Nama Pemohon" v-model="pemohon" readonly>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Alamat Pemohon<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="text" class="form-control form-control-sm"
                                                    id="alamatpemohon" name="alamatpemohon" placeholder="Alamat Pemohon"
                                                    v-model="alamatpemohon" readonly>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>No Telp<small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="number" class="form-control form-control-sm" id="notelp"
                                                    name="notelp" placeholder="No Telp" v-model="notelp" readonly>
                                            </div>
                                        </div>
                                        <div class="col-sm-12 previous" style="background-color: bisque;"
                                            v-for="(koodinatsetuju, counter) in koodinatsetujus" v-bind:key="counter">
                                            <div class="row">
                                                <div class="col-sm-12 text-left">
                                                    <label>Opsi Data</label>
                                                    <div class="col-sm-12 text-left">
                                                        <button type="button" class="btn btn-danger btn-sm"
                                                            @click="addKoordinat" disabled><i
                                                                class="far fa-plus-square"></i></button>
                                                        <button type="button" class="btn btn-danger btn-sm"
                                                            @click="deleteKoordinat(counter)" v-if="(counter > 0)" disabled><i
                                                                class="far fa-minus-square"></i></button>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Nama Alat<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'namalat' + (counter + 1)" name="namalat"
                                                            placeholder="Nama Alat" v-model="koodinatsetuju.namalat" readonly>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Merk<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'merk' + (counter + 1)" name="merk" placeholder="Merk"
                                                            v-model="koodinatsetuju.merk" readonly>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Tipe<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'tipe' + (counter + 1)" name="tipe" placeholder="Tipe"
                                                            v-model="koodinatsetuju.tipe" readonly>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Nomor Seri<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'noseri' + (counter + 1)" name="noseri"
                                                            placeholder="Nomor Seri" v-model="koodinatsetuju.noseri" readonly>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Kapasitas<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'kapasitas' + (counter + 1)" name="kapasitas"
                                                            placeholder="Kapasitas" v-model="koodinatsetuju.kapasitas" readonly>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Daya Baca<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'dayabaca' + (counter + 1)" name="dayabaca"
                                                            placeholder="Daya Baca" v-model="koodinatsetuju.dayabaca" readonly>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Buatan<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="text" class="form-control form-control-sm"
                                                            :id="'buatan' + (counter + 1)" name="buatan"
                                                            placeholder="Buatan" v-model="koodinatsetuju.buatan" readonly>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <label>Jumlah<small class="text-info"> (*wajib
                                                            diisi)</small></label>
                                                    <div class="input-group mb-3">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text"><i
                                                                    class="fab fa-creative-commons-share"></i></span>
                                                        </div>
                                                        <input type="number" class="form-control form-control-sm"
                                                            :id="'jumlah' + (counter + 1)" name="jumlah"
                                                            placeholder="Jumlah" v-model="koodinatsetuju.jumlah" readonly>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Layanan <small class="text-info"> (*wajib
                                                    dipilih)</small></label>
                                            <v-select :options="layanan" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedlayanan" @update:modelValue="onlayananChange" v-bind:class="{ disabled: true }"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Cetak Sertifikat </label>
                                            <div class="custom-control custom-switch custom-switch-on-danger">
                                                <input type="checkbox" class="custom-control-input" id="butuhserti"
                                                    v-model="butuhserti" disabled>
                                                <label class="custom-control-label" for="butuhserti">
                                                    Layanan Cetak Sertifikat <small class="text-info"> (*pilihan jika
                                                        membutuhan sertifikat)</small>
                                                </label>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <!--IKON PETA 1-->
                                        <div class="col-sm-12" style="display: none;">
                                            <label>Lampiran Surat Permohonan<small class="text-info"> (*pdf)</small>
                                            </label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="far fa-file-image"></i></span>
                                                </div>
                                                <input type="file" class="form-control form-control-sm"
                                                    id="fileToUploadfilenya_surat" name="fileToUploadfilenya_surat"
                                                    placeholder="fileToUpload" style="font-size: smaller;"
                                                    accept="application/pdf">
                                                <input type="hidden" class="form-control form-control-sm"
                                                    id="filenya_surat" name="filenya_surat" placeholder="File Slider">
                                            </div>
                                        </div>
                                        <div class="col-sm-12" style="display: none;">
                                            <label>Opsi</label>
                                            <div class="row">
                                                <div class="col-sm-9">
                                                    <div class="progress">
                                                        <div class="progress-bar" role="progressbar" aria-valuenow="0"
                                                            aria-valuemin="0" aria-valuemax="100" style="width:0%"
                                                            id="persennyafilenya_surat">
                                                            <span id="tulisanpersenfilenya_surat">0%</span>
                                                        </div>
                                                    </div>
                                                    <br>
                                                    <span class="text-success" id="suksesnyafilenya_surat"></span>
                                                </div>
                                                <div class="col-sm-3 text-center">
                                                    <button type="button"
                                                        class="btn btn-warning btn-sm lebar text-white"
                                                        @click="uploadfile('filenya_surat', 'application/pdf')">Upload
                                                        File</button>
                                                    <button type="button" class="btn btn-danger btn-sm lebar"
                                                        @click="clearfilepeta('filenya_surat')">Hapus File</button>
                                                </div>
                                            </div>
                                        </div>
                                        <!--IKON PETA 1 END-->
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-12">
                                            <label>Keterangan</label>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <quill-editor v-model:value="keterangan"
                                                        style="height: 25vh;" v-bind:class="{ disabled: true }"></quill-editor>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-12">&nbsp;</div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Kembali.."><i class="fas fa-window-close"></i> Kembali</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-12 text-center">&nbsp;</div>
        <div class="col-sm-12 text-center">&nbsp;</div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { quillEditor } from 'vue3-quill';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal, quillEditor
    },
    data() {
        return {
            datapesan: '',
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            pemohon: '',
            alamatpemohon: '',
            namapemilik: '',
            alamatpemilik: '',
            filenya_surat: '',
            notelp: '',
            namalat: '',
            merk: '',
            tipe: '',
            noseri: '',
            kapasitas: '',
            dayabaca: '',
            buatan: '',
            jumlah: '',
            keterangan: '',
            validasi: false,
            butuhserti: false,
            layanan: [],
            datalayanan: [],
            defaultSelectedlayanan: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedlayanan: 0,

            counter: 0,
            koodinatsetujus: [
                {
                    previous: '',
                    expiration: '',
                }
            ],
        }
    },
    methods: {
        addKoordinat() {
            this.koodinatsetujus.push({
                previous: '',
                expiration: ''
            })
        },
        deleteKoordinat(counter) {
            this.koodinatsetujus.splice(counter, 1);
        },
        initialize() {
            //Initialize Select2 Elements
            $(".select2").select2();

            //Initialize Select2 Elements
            $(".select2bs4").select2({
                theme: "bootstrap4",
            });
            $('[data-mask]').inputmask();
            //$('#summernote').summernote();
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        onlayananChange(a) {
            try {
                this.selectedlayanan = this.layanan[a].tag;
            }
            catch (err) {
                return;
            }
        },
        ontabelChange(a) {
            try {
                this.selectedtabel = this.tabel[a].tag;
            }
            catch (err) {
                return;
            }
        },
        async ambilreff() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_pengujian-GetLayanan?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datalayanan = [];
                        this.layanan = [];
                        this.datalayanan = Response.data.content.data;
                        this.datalayanan.forEach((item) => {
                            this.layanan.push({
                                label: item.nama,
                                code: this.datalayanan.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.layanan.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                        this.halamanloading = false;
                    }
                    else {
                        swal.fire('Peringatan', error, 'error');
                        this.halamanloading = false;
                        return false;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            });

            if (this.secretencData == 'Tambah') {
                await mainAPI.get("parastapainnovationUser-GetUser?random=" + random + "&kduser=" + this.iduser + "&level=" + this.leveluser).then(
                    Response => {
                        this.datapesan = Response.data.message;
                        //alert(this.datapesan);
                        if (this.datapesan == 'data diketemukan') {
                            this.pemohon = Response.data.content.data[0].nama;
                            this.alamatpemohon = Response.data.content.data[0].alamat;
                            this.pemilik = Response.data.content.data[0].nama;
                            this.alamatpemilik = Response.data.content.data[0].alamat;
                            this.notelp = Response.data.content.data[0].telp;
                            this.halamanloading = false;
                        }
                        else {
                            swal.fire('Peringatan', error, 'error');
                            this.halamanloading = false;
                            return false;
                        }
                        this.halamanloading = false;
                    }
                ).catch(error => {
                    swal.fire('Peringatan', error, 'error');
                    this.halamanloading = false;
                    return false;
                });
            }
        },
        batalproses() {
            window.location.replace("/Pengujian");
        },
        uploadfile(idnya, tipenya) {
            var elmnt = document.getElementById("fileToUpload" + idnya);
            try {
                var namanya = elmnt.files[0].name;
                //alert(elmnt.files[0]['type'].toUpperCase());
                if (tipenya == 'image/*') {
                    if ((elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/JPEG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/PNG') && (elmnt.files[0]['type'].toUpperCase() != 'IMAGE/GIF')) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format gambar';
                        return;
                    }
                }
                else {
                    if (elmnt.files[0]['type'] != tipenya) {
                        document.getElementById('suksesnya' + idnya).innerHTML = '*file harus format pdf';
                        return;
                    }
                }
            } catch (error) {
                document.getElementById('suksesnya' + idnya).innerHTML = '*pilih file terlebih dahulu';
                return;
            }
            var fd = new FormData();
            fd.append("fileToUpload", elmnt.files[0], elmnt.files[0].name);
            fd.append("folderToUpload", idnya);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesUpload", fd, {
                onUploadProgress: function (uploadEvent) {
                    document.getElementById('persennya' + idnya).style.width = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                    document.getElementById('tulisanpersen' + idnya).innerHTML = Math.round((uploadEvent.loaded / uploadEvent.total) * 100) + "%";
                }
            }).then(
                function (res) {
                    document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                    if (res.data.filename != undefined) {
                        document.getElementById(idnya).value = res.data.filename;
                    }
                }
            ).catch(function (e) {
                document.getElementById('suksesnya' + idnya).innerHTML = res.data.message;
                document.getElementById(idnya).value = '';
                console.log(e);
            }
            )
        },
        clearfilepeta(idnya) {
            if (document.getElementById(idnya).value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'File Tidak Tersedia',
                });
                return false;
            }

            var fd = new FormData();
            fd.append("folderToUpload", idnya);
            fd.append("filenya", document.getElementById(idnya).value);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation-FilesDeleted", fd).then(
                Response => {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: Response.data.message,
                    });
                    document.getElementById(idnya).value == '';
                    this.filenya_surat = '';
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )

            document.getElementById('fileToUpload' + idnya).value = '';
            document.getElementById(idnya).value = '';
            document.getElementById('persennya' + idnya).style.width = "0%";
            document.getElementById('tulisanpersen' + idnya).innerHTML = "0%";
            document.getElementById('suksesnya' + idnya).innerHTML = '';
            return false;
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("parastapainnovation_pengujian-GetPengujianByID?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.defaultSelectedlayanan = {
                        code: Response.data.content.data[0].layanan,
                        label: Response.data.content.data[0].namalayanan,
                        tag: Response.data.content.data[0].layanan,
                    };
                    this.selectedlayanan = Response.data.content.data[0].layanan;
                    this.pemohon = Response.data.content.data[0].pemohon;
                    this.alamatpemohon = Response.data.content.data[0].alamatpemohon;
                    this.pemilik = Response.data.content.data[0].pemilik;
                    this.alamatpemilik = Response.data.content.data[0].alamatpemilik;

                    this.notelp = Response.data.content.data[0].notelp;

                    var namalat = Response.data.content.data[0].namalat;
                    var jum_namalat = namalat.split("|");

                    var merk = Response.data.content.data[0].merk;
                    var jum_merk = merk.split("|");
                    var tipe = Response.data.content.data[0].tipe;
                    var jum_tipe = tipe.split("|");
                    var noseri = Response.data.content.data[0].noseri;
                    var jum_noseri = noseri.split("|");
                    var kapasitas = Response.data.content.data[0].kapasitas;
                    var jum_kapasitas = kapasitas.split("|");
                    var dayabaca = Response.data.content.data[0].dayabaca;
                    var jum_dayabaca = dayabaca.split("|");
                    var buatan = Response.data.content.data[0].buatan;
                    var jum_buatan = buatan.split("|");
                    var jumlah = Response.data.content.data[0].jumlah;
                    var jum_jumlah= jumlah.split("|");
                    this.koodinatsetujus = [];
                    for (let i = 0; i < jum_namalat.length; i++) {
                        this.koodinatsetujus.push({
                            previous: '',
                            expiration: '',
                            "namalat": jum_namalat[i],
                            "merk": jum_merk[i],
                            "tipe": jum_tipe[i],
                            "noseri": jum_noseri[i],
                            "kapasitas": jum_kapasitas[i],
                            "dayabaca": jum_dayabaca[i],
                            "buatan": jum_buatan[i],
                            "jumlah": jum_jumlah[i],
                        });
                    }

                    this.keterangan = Response.data.content.data[0].keterangan;
                    var tmpketerangan = Response.data.content.data[0].butuhserti;

                    if (tmpketerangan == 1) {
                        this.butuhserti = true;
                    }

                    this.filenya_surat = Response.data.content.data[0].filenya_surat;
                    document.getElementById('filenya_surat').value = Response.data.content.data[0].filenya_surat;
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        validasidata() {
            if (document.getElementById('pemilik').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('pemilik').focus();
                return false;
            }
            if (document.getElementById('alamatpemilik').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('alamatpemilik').focus();
                return false;
            }
            if (document.getElementById('pemohon').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('pemohon').focus();
                return false;
            }
            if (document.getElementById('alamatpemohon').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('alamatpemohon').focus();
                return false;
            }
            if (document.getElementById('notelp').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('notelp').focus();
                return false;
            }
            for (let i = 1; i <= this.koodinatsetujus.length; i++) {
                if (document.getElementById('namalat' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('namalat' + i).focus();
                    return false;
                }
                if (document.getElementById('merk' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('merk' + i).focus();
                    return false;
                }
                if (document.getElementById('tipe' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('tipe' + i).focus();
                    return false;
                }
                if (document.getElementById('noseri' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('noseri' + i).focus();
                    return false;
                }
                if (document.getElementById('kapasitas' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('kapasitas' + i).focus();
                    return false;
                }
                if (document.getElementById('dayabaca' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('dayabaca' + i).focus();
                    return false;
                }
                if (document.getElementById('buatan' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('buatan' + i).focus();
                    return false;
                }
                if ((document.getElementById('jumlah' + i).value == '') || (document.getElementById('jumlah' + i).value == '0')) {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('jumlah' + i).focus();
                    return false;
                }
            }

            if (document.getElementById('filenya_surat').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Lampirkan Surat Permohonan',
                });
                return false;
            }
            if ((this.selectedlayanan == '') || (this.selectedlayanan == undefined) || (this.selectedlayanan == null)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Data',
                });
                return false;
            }

            this.validasi = true;
            return;
        },
        simpan() {
            //alert(this.koodinatsetujus.length);
            //return false;
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }
            var serti = '0';
            if (this.butuhserti == true) {
                serti = '1';
            }
            //alert(serti);
            //return false;
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Data Sudah Benar Dan Hendak Menyimpan Data?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#DC143C",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Simpan"
            }).then((result) => {
                if (result.isConfirmed) {

                    var fd = new FormData();
                    var namalat = "";
                    var merk = "";
                    var tipe = "";
                    var noseri = "";
                    var kapasitas = "";
                    var dayabaca = "";
                    var buatan = "";
                    var jumlah = "";
                    for (let i = 1; i <= this.koodinatsetujus.length; i++) {
                        if (namalat == "") {
                            namalat = namalat + document.getElementById("namalat" + i).value;
                        }
                        else {
                            namalat = namalat + "|" + document.getElementById("namalat" + i).value;
                        }
                        if (merk == "") {
                            merk = merk + document.getElementById("merk" + i).value;
                        }
                        else {
                            merk = merk + "|" + document.getElementById("merk" + i).value;
                        }

                        if (tipe == "") {
                            tipe = tipe + document.getElementById("tipe" + i).value;
                        }
                        else {
                            tipe = tipe + "|" + document.getElementById("tipe" + i).value;
                        }
                        if (noseri == "") {
                            noseri = noseri + document.getElementById("noseri" + i).value;
                        }
                        else {
                            noseri = noseri + "|" + document.getElementById("noseri" + i).value;
                        }

                        if (kapasitas == "") {
                            kapasitas = kapasitas + document.getElementById("kapasitas" + i).value;
                        }
                        else {
                            kapasitas = kapasitas + "|" + document.getElementById("kapasitas" + i).value;
                        }
                        if (dayabaca == "") {
                            dayabaca = dayabaca + document.getElementById("dayabaca" + i).value;
                        }
                        else {
                            dayabaca = dayabaca + "|" + document.getElementById("dayabaca" + i).value;
                        }


                        if (buatan == "") {
                            buatan = buatan + document.getElementById("buatan" + i).value;
                        }
                        else {
                            buatan = buatan + "|" + document.getElementById("buatan" + i).value;
                        }
                        if (jumlah == "") {
                            jumlah = jumlah + document.getElementById("jumlah" + i).value;
                        }
                        else {
                            jumlah = jumlah + "|" + document.getElementById("jumlah" + i).value;
                        }
                    }
                    fd.append("layanan", this.selectedlayanan);
                    fd.append("pemilik", this.pemilik);
                    fd.append("alamatpemilik", this.alamatpemilik);
                    fd.append("pemohon", this.pemohon);
                    fd.append("alamatpemohon", this.alamatpemohon);
                    fd.append("notelp", this.notelp);
                    fd.append("namalat", namalat);
                    fd.append("merk", merk);
                    fd.append("tipe", tipe);
                    fd.append("noseri", noseri);
                    fd.append("kapasitas", kapasitas);
                    fd.append("dayabaca", dayabaca);
                    fd.append("buatan", buatan);
                    fd.append("jumlah", jumlah);
                    fd.append("keterangan", this.keterangan);
                    fd.append("filenya_surat", document.getElementById('filenya_surat').value);
                    fd.append("butuhserti", serti);
                    fd.append("createdby", JSON.parse(localStorage.getItem("usistem")));

                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            "Content-Type": "form-data",
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                        },
                    });
                    mainAPI.post("parastapainnovation_pengujian-AddPengujian", fd).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                                    window.location.replace("/Pengujian");
                                });
                            }
                        }
                    ).catch(function (error) {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: error
                        });
                    }
                    )
                }
            });
            return false;
        },
        update() {
            this.validasidata();
            if (this.validasi == false) {
                return false;
            }

            var serti = '0';
            if (this.butuhserti == true) {
                serti = '1';
            }
            //alert(serti);
            //return false;
            swal.fire({
                title: "Konfirmasi",
                text: "Yakin Data Sudah Benar Dan Hendak Menyimpan Data?",
                icon: "warning",
                showCancelButton: true,
                cancelButtonText: "Batal",
                confirmButtonColor: "#DC143C",
                cancelButtonColor: "#ffaa05",
                confirmButtonText: "Simpan"
            }).then((result) => {
                if (result.isConfirmed) {

                    var fd = new FormData();
                    var namalat = "";
                    var merk = "";
                    var tipe = "";
                    var noseri = "";
                    var kapasitas = "";
                    var dayabaca = "";
                    var buatan = "";
                    var jumlah = "";
                    for (let i = 1; i <= this.koodinatsetujus.length; i++) {
                        if (namalat == "") {
                            namalat = namalat + document.getElementById("namalat" + i).value;
                        }
                        else {
                            namalat = namalat + "|" + document.getElementById("namalat" + i).value;
                        }
                        if (merk == "") {
                            merk = merk + document.getElementById("merk" + i).value;
                        }
                        else {
                            merk = merk + "|" + document.getElementById("merk" + i).value;
                        }

                        if (tipe == "") {
                            tipe = tipe + document.getElementById("tipe" + i).value;
                        }
                        else {
                            tipe = tipe + "|" + document.getElementById("tipe" + i).value;
                        }
                        if (noseri == "") {
                            noseri = noseri + document.getElementById("noseri" + i).value;
                        }
                        else {
                            noseri = noseri + "|" + document.getElementById("noseri" + i).value;
                        }

                        if (kapasitas == "") {
                            kapasitas = kapasitas + document.getElementById("kapasitas" + i).value;
                        }
                        else {
                            kapasitas = kapasitas + "|" + document.getElementById("kapasitas" + i).value;
                        }
                        if (dayabaca == "") {
                            dayabaca = dayabaca + document.getElementById("dayabaca" + i).value;
                        }
                        else {
                            dayabaca = dayabaca + "|" + document.getElementById("dayabaca" + i).value;
                        }


                        if (buatan == "") {
                            buatan = buatan + document.getElementById("buatan" + i).value;
                        }
                        else {
                            buatan = buatan + "|" + document.getElementById("buatan" + i).value;
                        }
                        if (jumlah == "") {
                            jumlah = jumlah + document.getElementById("jumlah" + i).value;
                        }
                        else {
                            jumlah = jumlah + "|" + document.getElementById("jumlah" + i).value;
                        }
                    }
                    fd.append("layanan", this.selectedlayanan);
                    fd.append("pemilik", this.pemilik);
                    fd.append("alamatpemilik", this.alamatpemilik);
                    fd.append("pemohon", this.pemohon);
                    fd.append("alamatpemohon", this.alamatpemohon);
                    fd.append("notelp", this.notelp);
                    fd.append("namalat", namalat);
                    fd.append("merk", merk);
                    fd.append("tipe", tipe);
                    fd.append("noseri", noseri);
                    fd.append("kapasitas", kapasitas);
                    fd.append("dayabaca", dayabaca);
                    fd.append("buatan", buatan);
                    fd.append("jumlah", jumlah);
                    fd.append("keterangan", this.keterangan);
                    fd.append("filenya_surat", document.getElementById('filenya_surat').value);
                    fd.append("butuhserti", serti);
                    fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
                    fd.append("id", this.secretencData);

                    const mainAPI = axios.create({
                        baseURL: process.env.VUE_APP_URL_API,
                        headers: {
                            "Content-Type": "form-data",
                            Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                        },
                    });
                    mainAPI.post("parastapainnovation_pengujian-UpdatePengujian", fd).then(
                        Response => {
                            if (Response.data.response == 'error') {
                                swal.fire('Peringatan', Response.data.message, 'error');
                            }
                            else {
                                swal.fire('Informasi', 'Data telah tersimpan..', 'success').then(function () {
                                    window.location.replace("/Pengujian");
                                });
                            }
                        }
                    ).catch(function (error) {
                        swal.mixin({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        }).fire({
                            icon: 'warning',
                            title: error
                        });
                    }
                    )
                }
            });
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.initialize();
        this.ambilreff();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
        //alert(this.iduser);
        //alert(this.leveluser);
    }
}
</script>
<style>
.disabled {
  pointer-events:none;
  color: #eaeaea;
  cursor: not-allowed;
  background-image: none;
  background-color: #eaeaea;
  border-color: #eaeaea;   
}
</style>