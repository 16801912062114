<template>
    <div class="wrapper">
        <NavBar />
        <Menu />
        <!-- Spinner Start -->
        <div id="spinner" v-if="halamanloading"
            class="show bg-white position-fixed translate-middle w-100 vh-100 top-50 start-50 d-flex align-items-center justify-content-center"
            style="opacity: 0.5;">
            <div class="spinner-border text-danger" style="width: 3rem; height: 3rem;" role="status">
                <span class="sr-only">Loading...</span>
            </div>
        </div>
        <!-- Spinner End -->
        <div class="content-wrapper py-3">
            <div class="content-header">
                <div class="container-fluid">
                    <div class="row mb-2">
                        <div class="col-sm-6 wow fadeInDown">
                            <h5>Order</h5>
                        </div>
                        <div class="col-sm-6 wow fadeInDown">
                            <ol class="breadcrumb float-sm-right">
                                <li class="breadcrumb-item"><a href="/Dashboard">Home </a></li>
                                <li class="breadcrumb-item"><a href="/Order">Order</a></li>
                                <li class="breadcrumb-item active">Pengujian</li>
                            </ol>
                        </div>
                    </div><!-- /.row -->
                </div><!-- /.container-fluid -->
            </div>
            <!-- /.content-header -->
            <div class="content">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-sm-12 wow fadeInDown">
                            <div class="card cardputih2">
                                <div class="card-header border-transparent">
                                    <h3 class="card-title">Form Pengujian</h3>
                                    <div class="card-tools">
                                        <button type="button" class="btn btn-tool" data-card-widget="collapse">
                                            <i class="fas fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                                <div class="card-body p-3">
                                    <div class="row">
                                        <div class="col-sm-12">
                                            <div class="table-responsive table-wrapper">
                                                <table class="table m-0" id="tabeldata">
                                                    <thead>
                                                        <tr>
                                                            <th style="width: 50px;">No</th>
                                                            <th style="width: 150px;">Nama<br>Alat<br>Merek / Model /
                                                                Tipe / Nomor Seri</th>
                                                            <th style="width: 150px;">Merek / Model / Tipe / Nomor Seri
                                                            </th>
                                                            <th style="width: 20px;">Jumlah</th>
                                                            <th style="width: 150px;">Nominal Kap. / Resolusi<br>Hasil
                                                                Uji</th>
                                                            <th style="width: 150px;">Tarif Per Alat <br>Rp.</th>
                                                        </tr>
                                                    </thead>
                                                    <template v-for="(datalist, urutlist) in datadetail"
                                                        :key="urutlist">
                                                        <tbody>
                                                            <tr>
                                                                <td class="text-center">{{ urutlist + 1 }}</td>
                                                                <td class="text-justify">
                                                                    Nama Alat: {{ datalist.namalat }}<br> No Seri: {{
                                                                        datalist.noseri }}<br>
                                                                    Merk: {{ datalist.merk }},
                                                                    Tipe: {{ datalist.tipe }}<br>
                                                                </td>
                                                                <td>

                                                                    Kapasitas: {{ datalist.kapasitas }}<br>
                                                                    Daya Baca: {{ datalist.dayabaca }}<br>
                                                                    Buatan: {{ datalist.buatan }}
                                                                </td>
                                                                <td class="text-center">{{ datalist.jumlah }}</td>
                                                                <td>
                                                                    <label>Kapasitas Uji<small class="text-info">
                                                                            (*wajib
                                                                            diisi)</small></label>
                                                                    <div class="input-group mb-3">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"><i
                                                                                    class="fab fa-creative-commons-share"></i></span>
                                                                        </div>
                                                                        <input type="text"
                                                                            class="form-control form-control-sm"
                                                                            :id="'kapasitas_validasi' + (urutlist + 1)"
                                                                            :name="'kapasitas_validasi' + (urutlist + 1)"
                                                                            :value="datalist.kapasitas_validasi"
                                                                            placeholder="Kapasitas Uji">
                                                                    </div>
                                                                    <label>Daya Baca / Resolusi<small class="text-info">
                                                                            (*wajib
                                                                            diisi)</small></label>
                                                                    <div class="input-group mb-3">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"><i
                                                                                    class="fab fa-creative-commons-share"></i></span>
                                                                        </div>
                                                                        <input type="text"
                                                                            class="form-control form-control-sm"
                                                                            :id="'dayabaca_validasi' + (urutlist + 1)"
                                                                            :name="'dayabaca_validasi' + (urutlist + 1)"
                                                                            :value="datalist.dayabaca_validasi"
                                                                            placeholder="Daya Baca / Resolusi">
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="input-group mb-3">
                                                                        <div class="input-group-prepend">
                                                                            <span class="input-group-text"><i
                                                                                    class="fab fa-creative-commons-share"></i></span>
                                                                        </div>
                                                                        <input type="number"
                                                                            class="form-control form-control-sm"
                                                                            :id="'nilaitarif' + (urutlist + 1)"
                                                                            :name="'nilaitarif' + (urutlist + 1)"
                                                                            :value="datalist.nilaitarif"
                                                                            placeholder="Tarif" value="0">
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </template>
                                                </table>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12 text-justify">
                                                <div class="card card-warning collapsed-card">
                                                    <div class="card-header">
                                                        <h3 class="card-title" data-card-widget="collapse"
                                                            style="cursor: pointer;">Unsur Pengujian / Kaji Ulang
                                                        </h3>
                                                        <div class="card-tools">
                                                            <button type="button" class="btn btn-tool"
                                                                data-card-widget="collapse"><i class="fas fa-plus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                    <template v-for="(datanya, urut) in dataku" :key="urut">
                                                        <div class="card-body">
                                                            <div class="carddataputih">
                                                                <div class="card-body">
                                                                    <div class="row">
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                1. Kesesuaian ruang lingkup akredetasi
                                                                                KAN
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek1'"
                                                                                    :id="'radiotidakloloscek1'"
                                                                                    :checked="datanya.cek1 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek1'"
                                                                                    :id="'radiololoscek1'"
                                                                                    :checked="datanya.cek1 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                &nbsp;&nbsp; - Pelanggan menyetujui
                                                                                sertifikat tanpa logo KAN untuk
                                                                                pelayanan verifikasi diluar lingkup KAN
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek2'"
                                                                                    :id="'radiotidakloloscek2'"
                                                                                    :checked="datanya.cek2 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek2'"
                                                                                    :id="'radiololoscek2'"
                                                                                    :checked="datanya.cek2 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                2. Ketersediaan peralatan standar BSML
                                                                                Regional II
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek3'"
                                                                                    :id="'radiotidakloloscek3'"
                                                                                    :checked="datanya.cek3 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek3'"
                                                                                    :id="'radiololoscek3'"
                                                                                    :checked="datanya.cek3 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                3. Kesesuaian spesifikasi antara
                                                                                permohonan dengan yang tertera pada alat
                                                                                atau ketentuan berlaku
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek4'"
                                                                                    :id="'radiotidakloloscek4'"
                                                                                    :checked="datanya.cek4 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek4'"
                                                                                    :id="'radiololoscek4'"
                                                                                    :checked="datanya.cek4 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-12">
                                                                            <label style="font-size: small;">
                                                                                4. Pemeriksaan fungsi dan kelengkapan
                                                                                alat
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                &nbsp;&nbsp; a. Anak timbangan diperiksa
                                                                                kesesuaian ketentuan dan jumlahnya
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek5'"
                                                                                    :id="'radiotidakloloscek5'"
                                                                                    :checked="datanya.cek5 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek5'"
                                                                                    :id="'radiololoscek5'"
                                                                                    :checked="datanya.cek5 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                &nbsp;&nbsp; b. Timbangan elektronik
                                                                                berfungsi dengan baik dan dilengkapi
                                                                                adaptor
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek6'"
                                                                                    :id="'radiotidakloloscek6'"
                                                                                    :checked="datanya.cek6 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek6'"
                                                                                    :id="'radiololoscek6'"
                                                                                    :checked="datanya.cek6 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                &nbsp;&nbsp; c. Neraca lengkap, dapat
                                                                                dirakit dan berfungsi dengan normal
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek7'"
                                                                                    :id="'radiotidakloloscek7'"
                                                                                    :checked="datanya.cek7 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek7'"
                                                                                    :id="'radiololoscek7'"
                                                                                    :checked="datanya.cek7 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                &nbsp;&nbsp; d. Bejana Ukur tidak
                                                                                mengalami deformasi bentuk, tidak bocor,
                                                                                skala nonius lengkap, tidak pecah dan
                                                                                berfungsi dengan baik
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek8'"
                                                                                    :id="'radiotidakloloscek8'"
                                                                                    :checked="datanya.cek8 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek8'"
                                                                                    :id="'radiololoscek8'"
                                                                                    :checked="datanya.cek8 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                &nbsp;&nbsp; e. Gelas ukur dan labu ukur
                                                                                tidak pecah atau bocor
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek9'"
                                                                                    :id="'radiotidakloloscek9'"
                                                                                    :checked="datanya.cek9 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek9'"
                                                                                    :id="'radiololoscek9'"
                                                                                    :checked="datanya.cek9 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                &nbsp;&nbsp; f. Stopwatch, berfungsi
                                                                                dengan baik dan dilengkapi baterai, test
                                                                                start stop dan kondisi fisik
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek10'"
                                                                                    :id="'radiotidakloloscek10'"
                                                                                    :checked="datanya.cek10 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek10'"
                                                                                    :id="'radiololoscek10'"
                                                                                    :checked="datanya.cek10 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                &nbsp;&nbsp; g. Caliper/jangka sorong,
                                                                                berfungsi dengan baik dan dilengkapi
                                                                                baterai (digital), penunjukkan normal
                                                                                sesuai skala,<br>&nbsp;&nbsp;
                                                                                &nbsp;&nbsp; &nbsp;tidak mengalami
                                                                                deformasi bentuk/tidak bengkok
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek11'"
                                                                                    :id="'radiotidakloloscek11'"
                                                                                    :checked="datanya.cek11 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek11'"
                                                                                    :id="'radiololoscek11'"
                                                                                    :checked="datanya.cek11 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                &nbsp;&nbsp; h. Komparator sidang, skala
                                                                                jelas dan tidak mengalami deformasi
                                                                                bentuk / tidak bengkok
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek12'"
                                                                                    :id="'radiotidakloloscek12'"
                                                                                    :checked="datanya.cek12 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek12'"
                                                                                    :id="'radiololoscek12'"
                                                                                    :checked="datanya.cek12 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                &nbsp;&nbsp; i. Bourje, skala jelas dan
                                                                                tidak mengalami deformasi bentuk/tidak
                                                                                bengkok
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek13'"
                                                                                    :id="'radiotidakloloscek13'"
                                                                                    :checked="datanya.cek13 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek13'"
                                                                                    :id="'radiololoscek13'"
                                                                                    :checked="datanya.cek13 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                &nbsp;&nbsp; j. Thermometer dan
                                                                                thermohygrometer gelas memiliki kaca
                                                                                penunjukan skala berfungsi dengan baik
                                                                                dan tidak pecah
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek14'"
                                                                                    :id="'radiotidakloloscek14'"
                                                                                    :checked="datanya.cek14 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek14'"
                                                                                    :id="'radiololoscek14'"
                                                                                    :checked="datanya.cek14 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                &nbsp;&nbsp; k. Thermometer dan
                                                                                thermohygrometer digital berfungsi
                                                                                dengan baik dan dilengkapi baterai
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek15'"
                                                                                    :id="'radiotidakloloscek15'"
                                                                                    :checked="datanya.cek15 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek15'"
                                                                                    :id="'radiololoscek15'"
                                                                                    :checked="datanya.cek15 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                5. Pelanggan setuju dengan metode dan
                                                                                titik pengukuran yang telah ditentukan
                                                                                BSML Regional ll. Khusus untuk Timbangan
                                                                                Elektronik pelanggan dapat menentukan
                                                                                maksimal 10 titik pengukuran sedangkan
                                                                                thermometer dan gelas ukur 3 titik
                                                                                pengukuran

                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek16'"
                                                                                    :id="'radiotidakloloscek16'"
                                                                                    :checked="datanya.cek16 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek16'"
                                                                                    :id="'radiololoscek16'"
                                                                                    :checked="datanya.cek16 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                6. Pelanggan menerima resiko hasil
                                                                                verifikasi timbangan/neraca yang
                                                                                dilakukan tidak pada tempat
                                                                                penggunaannya
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek17'"
                                                                                    :id="'radiotidakloloscek17'"
                                                                                    :checked="datanya.cek17 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek17'"
                                                                                    :id="'radiololoscek17'"
                                                                                    :checked="datanya.cek17 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                7. Pelanggan menyetujui pencantuman masa
                                                                                berlaku, pernyataan kesesuaian, opini
                                                                                dan interprestasi pada sertifikat
                                                                                verifikasi sesuai dengan standar yang
                                                                                digunakan dan ketentuan yang berlaku
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek18'"
                                                                                    :id="'radiotidakloloscek18'"
                                                                                    :checked="datanya.cek18 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek18'"
                                                                                    :id="'radiololoscek18'"
                                                                                    :checked="datanya.cek18 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                8. Barang milik pelanggan tidak dalam
                                                                                permasalahan hukum
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek19'"
                                                                                    :id="'radiotidakloloscek19'"
                                                                                    :checked="datanya.cek19 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek19'"
                                                                                    :id="'radiololoscek19'"
                                                                                    :checked="datanya.cek19 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                9. Barang milik pelangan diluar ruang
                                                                                lingkup Surat Keterangan Kemampuan
                                                                                Verifikasi Standar Kerja Secara Mandiri
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek20'"
                                                                                    :id="'radiotidakloloscek20'"
                                                                                    :checked="datanya.cek20 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek20'"
                                                                                    :id="'radiololoscek20'"
                                                                                    :checked="datanya.cek20 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                10. Sertifikat dapat dikirim via
                                                                                ekspedisi dengan biaya dari BSML
                                                                                Regional II untuk 1x pengiriman namun
                                                                                resiko kerusakan yang muncul selama
                                                                                proses pengiriman bukan tanggung jawab
                                                                                BSML Regional II
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek21'"
                                                                                    :id="'radiotidakloloscek21'"
                                                                                    :checked="datanya.cek21 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek21'"
                                                                                    :id="'radiololoscek21'"
                                                                                    :checked="datanya.cek21 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                11. BSML Regional ll tidak
                                                                                bertangungjawab atas resiko kehilangan
                                                                                dan kerusakan terhadap barang yang tidak
                                                                                diambil 30 hari setelah konfirmasi
                                                                                barang selesai.
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek22'"
                                                                                    :id="'radiotidakloloscek22'"
                                                                                    :checked="datanya.cek22 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek22'"
                                                                                    :id="'radiololoscek22'"
                                                                                    :checked="datanya.cek22 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                12. Permohonan verifikasi pengadaan baru
                                                                                milik UML dilengkapi dengan Berita Acara
                                                                                Serah Terima (BAST)
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek23'"
                                                                                    :id="'radiotidakloloscek23'"
                                                                                    :checked="datanya.cek23 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek23'"
                                                                                    :id="'radiololoscek23'"
                                                                                    :checked="datanya.cek23 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                13. Permohonan verifikasi untuk barang
                                                                                milik pihak ketiga yang digunakan dalam
                                                                                rangka kerja sama operasional (KSO)
                                                                                pelayanan tera dan tera ulang dilengkapi
                                                                                dengan salinan dokumen KSO.
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek24'"
                                                                                    :id="'radiotidakloloscek24'"
                                                                                    :checked="datanya.cek24 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek24'"
                                                                                    :id="'radiololoscek24'"
                                                                                    :checked="datanya.cek24 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-10">
                                                                            <label style="font-size: small;">
                                                                                14. BSML Regional II tidak bertanggung
                                                                                jawab terhadap kerusakan barang milik
                                                                                pelanggan dikarenakan force meijure.
                                                                            </label>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek25'"
                                                                                    :id="'radiotidakloloscek25'"
                                                                                    :checked="datanya.cek25 == 1">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Sesuai</label>
                                                                            </div>
                                                                        </div>
                                                                        <div class="col-sm-1">
                                                                            <div class="form-check">
                                                                                <input class="form-check-input"
                                                                                    type="radio" :name="'radiocek25'"
                                                                                    :id="'radiololoscek25'"
                                                                                    :checked="datanya.cek25 == 2">
                                                                                <label class="form-check-label"
                                                                                    style="font-size: small;">Tidak</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <!-- /.row -->
                                                                </div>
                                                                <!-- /.card-body -->
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <!-- /.card-body -->
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-6">
                                            <label>Hasil / Kesimpulan <small class="text-info"> (*wajib
                                                    dipilih)</small></label>
                                            <v-select :options="hasil" :reduce="(label) => label.code" label="label"
                                                v-model="defaultSelectedhasil" @update:modelValue="onhasilChange"
                                                style="background-color: #ffffff;"></v-select>
                                        </div>
                                        <div class="col-sm-6">
                                            <label>Tanggal Periksa <small class="text-info"> (*wajib
                                                    diisi)</small></label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text"><i
                                                            class="fab fa-creative-commons-share"></i></span>
                                                </div>
                                                <input type="date" class="form-control form-control-sm"
                                                    id="tanggalperiksa" name="tanggalperiksa"
                                                    placeholder="Tanggal Selesai" v-model="tanggalperiksa">
                                            </div>
                                        </div>
                                        <div class="col-sm-12">
                                            <label>Keterangan</label>
                                            <div class="row">
                                                <div class="col-sm-12">
                                                    <quill-editor v-model:value="catatan"
                                                        style="height: 25vh;"></quill-editor>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-12">&nbsp;</div>
                                        <div class="col-sm-12">&nbsp;</div>
                                    </div>
                                </div>
                                <div class="card-footer p-3">
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        @click="simpan()" v-if="(secretencData == 'Tambah')" data-toggle="tooltip"
                                        data-placement="left" title="Simpan data.."><i class="fas fa-plus-square"></i>
                                        Simpan</button>
                                    <button type="button" class="btn btn-sm btn-success3 float-left text-white lebar2"
                                        v-if="(secretencData != 'Tambah')" @click="update(secretencData)"
                                        data-toggle="tooltip" data-placement="left" title="Update data.."><i
                                            class="fa fa-check-circle"></i>
                                        Update</button>
                                    <button type="button" class="btn btn-sm btn-danger3 float-left text-white lebar2"
                                        @click="batalproses()" data-toggle="tooltip" data-placement="left"
                                        title="Batalkan proses.."><i class="fas fa-window-close"></i>
                                        Batal</button>
                                </div>
                            </div>
                            <div class="col-sm-12">&nbsp;</div>
                            <div class="col-sm-12">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
    </div>
</template>

<script>
import $ from 'jquery';
import axios from 'axios';
import vSelect from 'vue-select';
import swal from 'sweetalert2';
import CryptoJS from 'crypto-js';
import { useRoute } from 'vue-router';
import { watch } from 'vue';
import { quillEditor } from 'vue3-quill';
import Footer from "../../componen/Footer.vue";
import NavBar from "../../componen/NavBar.vue";
import Menu from "../../componen/Menu.vue";

import VueApexCharts from "vue3-apexcharts";
window.Apex.chart = { fontFamily: "Poppins-SemiBold, Arial, sans-serif" };
export default {
    setup() {
        const route = useRoute()
        watch(() => route.name, () => {
        });

        return { route };
    },
    components: {
        Footer, NavBar, Menu, vSelect, swal, quillEditor
    },
    data() {
        return {
            halamanloading: true,
            iduser: JSON.parse(localStorage.getItem("usistem")),
            leveluser: JSON.parse(localStorage.getItem("lsistem")),
            namauser: JSON.parse(localStorage.getItem("nmusistem")),
            secretencData: decodeURIComponent(CryptoJS.AES.decrypt(this.route.params.id, 'oriproMop8LQ06S0rZXcJyEH3wk8jVrINbHwn7tBq2').toString(CryptoJS.enc.Utf8)),
            noorder: '',
            keterangan: '',
            createddate: '',
            validasi: false,
            dataku: [],
            datadetail: [],
            tarif: [],
            datatarif: [],
            defaultSelectedtarif: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedtarif: 0,

            namaserti: '',
            alamatserti: '',

            datapermohonan: [],
            permohonan: [],
            defaultSelectedpermohonan: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedpermohonan: 0,

            paramselectedpermohonan: [],
            arraynamapermohonan: [],

            counter: 0,
            koodinatsetujus: [],

            hasil: [],
            datahasil: [],
            defaultSelectedhasil: {
                code: 0,
                label: 'Pilih Salah Satu',
                tag: '',
            },
            selectedhasil: 0,
            catatan: '',
            signdate: '',
            tanggalperiksa: '',
        }
    },
    methods: {
        initialize() {
            //Initialize Select2 Elements
            $(".select2").select2();

            //Initialize Select2 Elements
            $(".select2bs4").select2({
                theme: "bootstrap4",
            });
            $('[data-mask]').inputmask();
            //$('#summernote').summernote();
        },
        getOtentifikasi() {
            var time = Date.parse(localStorage.getItem("tsistem"));
            var timeakhir = time + (3 * 60 * 60 * 1000);
            var timesekarang = Date.parse(new Date());
            if (timesekarang > timeakhir) {
                this.ClearlocalStorage();
            }
            this.halamanloading = false;
        },
        onhasilChange(a) {
            try {
                this.selectedhasil = this.hasil[a].tag;
            }
            catch (err) {
                return;
            }
        },
        async ambilreff() {
            this.halamanloading = true;
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPI.get("parastapainnovation_order-GetHasil?random=" + random).then(
                Response => {
                    this.datapesan = Response.data.message;
                    if (this.datapesan == 'data diketemukan') {
                        this.datahasil = [];
                        this.hasil = [];
                        this.datahasil = Response.data.content.data;
                        this.datahasil.forEach((item) => {
                            this.hasil.push({
                                label: item.nama,
                                code: this.datahasil.indexOf(item) + 1,
                                tag: item.id,
                            });
                        });
                        this.hasil.unshift({
                            label: 'Pilih Salah Satu',
                            code: 0,
                            tag: '',
                        });
                        this.halamanloading = false;
                    }
                    else {
                        swal.fire('Peringatan', error, 'error');
                        this.halamanloading = false;
                        return false;
                    }
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            });
        },
        batalproses() {
            window.location.replace("/Order");
        },
        async AmbilDataSingel() {
            this.halamanloading = true;
            var mainAPIDataSingel = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            var random = Math.random();
            await mainAPIDataSingel.get("parastapainnovation_order-GetOrderByID?id=" + this.secretencData + "&random=" + random).then(
                Response => {
                    this.datadetail = [];
                    this.datadetail = Response.data.content.data[0].detail;
                    
                    this.dataku = [];
                    this.dataku = Response.data.content.data;
                    this.defaultSelectedhasil = {
                        code: Response.data.content.data[0].hasil,
                        label: Response.data.content.data[0].namahasil,
                        tag: Response.data.content.data[0].hasil,
                    };
                    this.selectedhasil = Response.data.content.data[0].hasil;
                    this.tanggalperiksa = Response.data.content.data[0].tanggalperiksa;
                    this.catatan = Response.data.content.data[0].catatan;
                    this.halamanloading = false;
                }
            ).catch(error => {
                swal.fire('Peringatan', error, 'error');
                this.halamanloading = false;
                return false;
            })
        },
        validasidata(id) {
            for (let i = 1; i <= this.datadetail.length; i++) {
                if (document.getElementById('kapasitas_validasi' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('kapasitas_validasi' + i).focus();
                    return false;
                }
                if (document.getElementById('dayabaca_validasi' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('dayabaca_validasi' + i).focus();
                    return false;
                }
                if (document.getElementById('nilaitarif' + i).value == '') {
                    swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    }).fire({
                        icon: 'warning',
                        title: 'Mohon Isikan Data',
                    });
                    document.getElementById('nilaitarif' + i).focus();
                    return false;
                }
            }
            if (document.getElementById('tanggalperiksa').value == '') {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Isikan Data',
                });
                document.getElementById('tanggalperiksa').focus();
                return false;
            }

            if ((this.selectedhasil == '') || (this.selectedhasil == undefined) || (this.selectedhasil == null)) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: 'Mohon Pilih Data',
                });
                return false;
            }

            this.validasi = true;
            return;
        },
        update(id) {
            //alert(this.datadetail.length);
            //return;
            this.validasidata(id);
            if (this.validasi == false) {
                return false;
            }
            var kapasitas_validasi = '';
            var dayabaca_validasi = '';
            var nilaitarif = '';
            for (let i = 1; i <= this.datadetail.length; i++) {
                if (i == 1) {
                    kapasitas_validasi = kapasitas_validasi + document.getElementById('kapasitas_validasi' + i).value;
                    dayabaca_validasi = dayabaca_validasi + document.getElementById('dayabaca_validasi' + i).value;
                    nilaitarif = nilaitarif + document.getElementById('nilaitarif' + i).value;
                }
                else {
                    kapasitas_validasi = kapasitas_validasi + '|' + document.getElementById('kapasitas_validasi' + i).value;
                    dayabaca_validasi = dayabaca_validasi + '|' + document.getElementById('dayabaca_validasi' + i).value;
                    nilaitarif = nilaitarif + '|' + document.getElementById('nilaitarif' + i).value;
                }
            }
            var cek1 = '';
            if (document.getElementById('radiotidakloloscek1').checked == true) {
                cek1 = '1'
            }
            if (document.getElementById('radiololoscek1').checked == true) {
                cek1 = '2'
            }

            var cek2 = '';
            if (document.getElementById('radiotidakloloscek2').checked == true) {
                cek2 = '1'
            }
            if (document.getElementById('radiololoscek2').checked == true) {
                cek2 = '2'
            }

            var cek3 = '';
            if (document.getElementById('radiotidakloloscek3').checked == true) {
                cek3 = '1'
            }
            if (document.getElementById('radiololoscek3').checked == true) {
                cek3 = '2'
            }

            var cek4 = '';
            if (document.getElementById('radiotidakloloscek4').checked == true) {
                cek4 = '1'
            }
            if (document.getElementById('radiololoscek4').checked == true) {
                cek4 = '2'
            }

            var cek5 = '';
            if (document.getElementById('radiotidakloloscek5').checked == true) {
                cek5 = '1'
            }
            if (document.getElementById('radiololoscek5').checked == true) {
                cek5 = '2'
            }

            var cek6 = '';
            if (document.getElementById('radiotidakloloscek6').checked == true) {
                cek6 = '1'
            }
            if (document.getElementById('radiololoscek6').checked == true) {
                cek6 = '2'
            }

            var cek7 = '';
            if (document.getElementById('radiotidakloloscek7').checked == true) {
                cek7 = '1'
            }
            if (document.getElementById('radiololoscek7').checked == true) {
                cek7 = '2'
            }

            var cek8 = '';
            if (document.getElementById('radiotidakloloscek8').checked == true) {
                cek8 = '1'
            }
            if (document.getElementById('radiololoscek8').checked == true) {
                cek8 = '2'
            }

            var cek9 = '';
            if (document.getElementById('radiotidakloloscek9').checked == true) {
                cek9 = '1'
            }
            if (document.getElementById('radiololoscek9').checked == true) {
                cek9 = '2'
            }

            var cek10 = '';
            if (document.getElementById('radiotidakloloscek10').checked == true) {
                cek10 = '1'
            }
            if (document.getElementById('radiololoscek10').checked == true) {
                cek10 = '2'
            }

            var cek11 = '';
            if (document.getElementById('radiotidakloloscek11').checked == true) {
                cek11 = '1'
            }
            if (document.getElementById('radiololoscek11').checked == true) {
                cek11 = '2'
            }

            var cek12 = '';
            if (document.getElementById('radiotidakloloscek12').checked == true) {
                cek12 = '1'
            }
            if (document.getElementById('radiololoscek12').checked == true) {
                cek12 = '2'
            }

            var cek13 = '';
            if (document.getElementById('radiotidakloloscek13').checked == true) {
                cek13 = '1'
            }
            if (document.getElementById('radiololoscek13').checked == true) {
                cek13 = '2'
            }

            var cek14 = '';
            if (document.getElementById('radiotidakloloscek14').checked == true) {
                cek14 = '1'
            }
            if (document.getElementById('radiololoscek14').checked == true) {
                cek14 = '2'
            }

            var cek15 = '';
            if (document.getElementById('radiotidakloloscek15').checked == true) {
                cek15 = '1'
            }
            if (document.getElementById('radiololoscek15').checked == true) {
                cek15 = '2'
            }

            var cek16 = '';
            if (document.getElementById('radiotidakloloscek16').checked == true) {
                cek16 = '1'
            }
            if (document.getElementById('radiololoscek16').checked == true) {
                cek16 = '2'
            }

            var cek17 = '';
            if (document.getElementById('radiotidakloloscek17').checked == true) {
                cek17 = '1'
            }
            if (document.getElementById('radiololoscek17').checked == true) {
                cek17 = '2'
            }

            var cek18 = '';
            if (document.getElementById('radiotidakloloscek18').checked == true) {
                cek18 = '1'
            }
            if (document.getElementById('radiololoscek18').checked == true) {
                cek18 = '2'
            }

            var cek19 = '';
            if (document.getElementById('radiotidakloloscek19').checked == true) {
                cek19 = '1'
            }
            if (document.getElementById('radiololoscek19').checked == true) {
                cek19 = '2'
            }

            var cek20 = '';
            if (document.getElementById('radiotidakloloscek20').checked == true) {
                cek20 = '1'
            }
            if (document.getElementById('radiololoscek20').checked == true) {
                cek20 = '2'
            }

            var cek21 = '';
            if (document.getElementById('radiotidakloloscek21').checked == true) {
                cek21 = '1'
            }
            if (document.getElementById('radiololoscek21').checked == true) {
                cek21 = '2'
            }

            var cek22 = '';
            if (document.getElementById('radiotidakloloscek22').checked == true) {
                cek22 = '1'
            }
            if (document.getElementById('radiololoscek22').checked == true) {
                cek22 = '2'
            }

            var cek23 = '';
            if (document.getElementById('radiotidakloloscek23').checked == true) {
                cek23 = '1'
            }
            if (document.getElementById('radiololoscek23').checked == true) {
                cek23 = '2'
            }

            var cek24 = '';
            if (document.getElementById('radiotidakloloscek24').checked == true) {
                cek24 = '1'
            }
            if (document.getElementById('radiololoscek24').checked == true) {
                cek24 = '2'
            }

            var cek25 = '';
            if (document.getElementById('radiotidakloloscek25').checked == true) {
                cek25 = '1'
            }
            if (document.getElementById('radiololoscek25').checked == true) {
                cek25 = '2'
            }
            var fd = new FormData();
            fd.append("kapasitas_validasi", kapasitas_validasi);
            fd.append("dayabaca_validasi", dayabaca_validasi);
            fd.append("nilaitarif", nilaitarif);
            fd.append("tanggalperiksa", this.tanggalperiksa);
            fd.append("hasil", this.selectedhasil);
            fd.append("catatan", this.catatan);
            fd.append("cek1", cek1);
            fd.append("cek2", cek2);
            fd.append("cek3", cek3);
            fd.append("cek4", cek4);
            fd.append("cek5", cek5);
            fd.append("cek6", cek6);
            fd.append("cek7", cek7);
            fd.append("cek8", cek8);
            fd.append("cek9", cek9);
            fd.append("cek10", cek10);
            fd.append("cek11", cek11);
            fd.append("cek12", cek12);
            fd.append("cek13", cek13);
            fd.append("cek14", cek14);
            fd.append("cek15", cek15);
            fd.append("cek16", cek16);
            fd.append("cek17", cek17);
            fd.append("cek18", cek18);
            fd.append("cek19", cek19);
            fd.append("cek20", cek20);
            fd.append("cek21", cek21);
            fd.append("cek22", cek22);
            fd.append("cek23", cek23);
            fd.append("cek24", cek24);
            fd.append("cek25", cek25);
            fd.append("tahapan", '5');
            fd.append("updatedby", JSON.parse(localStorage.getItem("usistem")));
            fd.append("id", this.secretencData);
            const mainAPI = axios.create({
                baseURL: process.env.VUE_APP_URL_API,
                headers: {
                    "Content-Type": "form-data",
                    Authorization: "Bearer " + localStorage.getItem("tokensistem"),
                },
            });
            mainAPI.post("parastapainnovation_order-UpdateOrder", fd).then(
                Response => {
                    if (Response.data.response == 'error') {
                        swal.fire('Peringatan', Response.data.message, 'error');
                    }
                    else {
                        swal.fire('Informasi', 'Data telah terupdate..', 'success').then(function () {
                            window.location.replace("/Order");
                        });
                    }
                }
            ).catch(function (error) {
                swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                }).fire({
                    icon: 'warning',
                    title: error
                });
            }
            )
            return false;
        },
    },
    mounted() {
        this.getOtentifikasi();
        this.initialize();
        this.ambilreff();
        if (this.secretencData != 'Tambah') {
            this.AmbilDataSingel();
        }
    }
}
</script>
<style></style>