<template>
  <div class="content" v-if="route.name == 'Beranda'">
    <Beranda/>
  </div>
  <div class="content" v-if="route.name == 'Login'">
    <Login/>
  </div>
  <div class="content" v-if="route.name == 'KontakKami'">
    <KontakKami/>
  </div>
  <div class="content" v-if="route.name == 'Dashboard'">
    <Dashboard/>
  </div>
  <div class="content" v-if="route.name == 'Slider'">
    <Slider/>
  </div>
  <div class="content" v-if="route.name == 'ProsesSlider'">
    <ProsesSlider/>
  </div>
  <div class="content" v-if="route.name == 'Pengujian'">
    <Pengujian/>
  </div>
  <div class="content" v-if="route.name == 'ProsesPengujian'">
    <ProsesPengujian/>
  </div>
  <div class="content" v-if="route.name == 'LihatPengujian'">
    <LihatPengujian/>
  </div>
  <div class="content" v-if="route.name == 'Proses'">
    <Proses/>
  </div>
  <div class="content" v-if="route.name == 'ProsesProses'">
    <ProsesProses/>
  </div>
  <div class="content" v-if="route.name == 'Order'">
    <Order/>
  </div>
  <div class="content" v-if="route.name == 'ProsesOrder'">
    <ProsesOrder/>
  </div>
  <div class="content" v-if="route.name == 'InfoBeranda'">
    <InfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'ProsesInfoBeranda'">
    <ProsesInfoBeranda/>
  </div>
  <div class="content" v-if="route.name == 'User'">
    <User/>
  </div>
  <div class="content" v-if="route.name == 'ProsesUser'">
    <ProsesUser/>
  </div>
  <div class="content" v-if="route.name == 'Data'">
    <Datanya/>
  </div>
  <div class="content" v-if="route.name == 'Data3'">
    <Datanya3/>
  </div>
  <div class="content" v-if="route.name == 'Data4'">
    <Datanya4/>
  </div>
  <div class="content" v-if="route.name == 'Data5'">
    <Datanya5/>
  </div>
  <div class="content" v-if="route.name == 'Data6'">
    <Datanya6/>
  </div>
  <div class="content" v-if="route.name == 'Data7'">
    <Datanya7/>
  </div>
  <div class="content" v-if="route.name == 'GIS'">
    <GIS/>
  </div>
  <div class="content" v-if="route.name == 'KategoriData'">
    <KategoriData/>
  </div>
  <div class="content" v-if="route.name == 'ProsesKategoriData'">
    <ProsesKategoriData/>
  </div>
  <div class="content" v-if="route.name == 'LayerPeta'">
    <LayerPeta/>
  </div>
  <div class="content" v-if="route.name == 'ProsesLayerPeta'">
    <ProsesLayerPeta/>
  </div>
</template>

<script>
import Beranda from './components/views/Publik/Beranda.vue';
import Login from './components/views/Publik/Login.vue';
import KontakKami from './components/views/Publik/KontakKami.vue';
import Dashboard from './components/views/Dashboard/Dashboard.vue';
import Slider from './components/views/Backoffice/Slider/Slider.vue';
import ProsesSlider from './components/views/Backoffice/Slider/ProsesSlider.vue';
import InfoBeranda from './components/views/Backoffice/InfoBeranda/InfoBeranda.vue';
import ProsesInfoBeranda from './components/views/Backoffice/InfoBeranda/ProsesInfoBeranda.vue';
import User from './components/views/Backoffice/User/User.vue';
import ProsesUser from './components/views/Backoffice/User/ProsesUser.vue';
import Datanya from './components/views/Backoffice/Data/Data.vue';
import Datanya3 from './components/views/Backoffice/Data/Data3.vue';
import Datanya4 from './components/views/Backoffice/Data/Data4.vue';
import Datanya5 from './components/views/Backoffice/Data/Data5.vue';
import Datanya6 from './components/views/Backoffice/Data/Data6.vue';
import Datanya7 from './components/views/Backoffice/Data/Data7.vue';
import Pengujian from './components/views/Backoffice/Pengujian/Pengujian.vue';
import ProsesPengujian from './components/views/Backoffice/Pengujian/ProsesPengujian.vue';
import LihatPengujian from './components/views/Backoffice/Pengujian/LihatPengujian.vue';
import Proses from './components/views/Backoffice/Proses/Proses.vue';
import ProsesProses from './components/views/Backoffice/Proses/ProsesProses.vue';
import Order from './components/views/Backoffice/Order/Order.vue';
import ProsesOrder from './components/views/Backoffice/Order/ProsesOrder.vue';
import GIS from './components/views/Publik/GIS.vue';
import KategoriData from './components/views/Backoffice/KategoriData/KategoriData.vue';
import ProsesKategoriData from './components/views/Backoffice/KategoriData/ProsesKategoriData.vue';
import LayerPeta from './components/views/Backoffice/LayerPeta/LayerPeta.vue';
import ProsesLayerPeta from './components/views/Backoffice/LayerPeta/ProsesLayerPeta.vue';


import { useRoute } from 'vue-router';
import { watch } from 'vue';

export default {
  name: 'App',
  components: {
    Beranda,Login,KontakKami,Dashboard
    ,Slider,ProsesSlider,InfoBeranda,ProsesInfoBeranda
    ,User,ProsesUser,Datanya,Datanya3,Datanya4
    ,Datanya5,Datanya6,Datanya7,Pengujian,ProsesPengujian,LihatPengujian,Proses,ProsesProses
    ,Order,ProsesOrder,GIS,KategoriData,ProsesKategoriData,LayerPeta,ProsesLayerPeta
  },
  mounted() {
    document.title = "Pak Camat Mesem";
  },
  setup() {
    const route = useRoute()
    watch(() => route.name, () => {
    });

    return { route };
  }
}
</script>

<style></style>
